<template>
  <StyledModal :visible="visible" title="视频" @close="close" @submit="submit" :submitLoading="submitLoading">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="90px">
      <el-form-item label="视频标题" prop="videoName" :rules="[{ required: true, message: '请输入' }]">
        <el-input v-model="formModel.form.videoName" placeholder="请输入视频标题"></el-input>
      </el-form-item>
      <el-form-item label="所属医生" prop="doctorCode" :rules="[{ required: true, message: '请输入' }]">
        <FetchSelect
          placeholder="所属医生"
          v-model="formModel.form.doctorCode"
          api="/web/system/doctor/getDoctorList"
          :postData="{
            page: 1,
            rows: 999,
          }"
          textKey="doctorName"
          valueKey="doctorCode"
          :isPaging="true"
        />
      </el-form-item>
      <el-form-item label="所属分类" prop="groupCode" :rules="[{ required: true, message: '请输入' }]">
        <FetchSelect
          placeholder="所属分类"
          v-model="formModel.form.groupCode"
          api="/web/system/videoGroup/getVideoGroupListPaging"
          :postData="{
            page: 1,
            rows: 999,
            // orgCode: userInfo?.orgCode || '',
          }"
          textKey="groupName"
          valueKey="groupCode"
          :isPaging="true"
        />
      </el-form-item>
      <el-form-item label="首页显示" prop="ifIdxShow" :rules="[{ required: true, message: '请选择' }]">
        <el-select placeholder="是否在首页显示" v-model="formModel.form.ifIdxShow" clearable>
          <el-option label="是" :value="'1'"></el-option>
          <el-option label="否" :value="'0'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="封面" prop="coverImg" :rules="[{ required: true, message: '请添加封面' }]">
        <div>
          <div><ImgUpload v-model="formModel.form.coverImg" :limit="1" /></div>
          <div :style="{ color: 'red' }">建议尺寸 561*760</div>
        </div>
      </el-form-item>
      <el-form-item label="上传视频" prop="videoUrl" :rules="[{ required: true, message: '请上传视频' }]">
        <div>
          <div><FileUpload v-model="formModel.form.videoUrl" :limit="1" /></div>
        </div>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="videoOrder"
        :rules="[
          { required: true, message: '请输入排序' },
          { type: 'number', min: 1, max: 99999, message: '请输入0-99999之间的数字' },
        ]"
      >
        <el-input v-model.number="formModel.form.videoOrder" placeholder="0-99999之间"></el-input>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input v-model="formModel.form.remark" placeholder="请输入" type="textarea"></el-input>
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import StyledModal from '@/components/modal/StyledModal'
import FetchSelect from '@/components/FetchSelect'
import request from '@/utils/request'
import { validateFormRef } from '@/common/utils_element'
import ImgUpload from '@/components/ImgUpload'
import FileUpload from '@/components/FileUpload/index.vue'

const defaultForm = {
  videoName: '',
  groupCode: '',
  doctorCode: '',
  coverImg: '',
  videoUrl: '',
  videoOrder: 1,
  ifIdxShow: '0',
  remark: '',
}

export default {
  emits: {
    successAdd: null,
    successEdit: null,
  },
  setup(_, ctx) {
    const store = useStore()
    const userInfo = computed(() => store.state.user.userInfo)

    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: {
        ...defaultForm,
      },
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    /**
     * 方法
     */

    async function open(recordParam) {
      visible.value = true
      if (recordParam) {
        getInfo(recordParam?.videoCode)
      }
    }

    const getInfo = async (videoCode) => {
      submitLoading.value = true
      try {
        const data = await request({
          url: '/web/system/video/getVideoInfo',
          data: {
            videoCode,
          },
        })
        record.v = data
        submitLoading.value = false
        console.log(data)
        renderForm(data)
      } catch (err) {
        submitLoading.value = false
      }
    }

    function close() {
      record.v = null
      visible.value = false
      formModel.form = {
        ...defaultForm,
      }
    }

    // form回显
    function renderForm(data) {
      formModel.form = data
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)

      submitLoading.value = true
      // 员工信息
      let data1
      try {
        data1 = await request({
          url: isAdd.value ? '/web/system/video/createVideo' : '/web/system/video/updateVideo',
          data: {
            ...formModel.form,
            ...(isAdd.value ? {} : { videoCode: record.v?.videoCode }),
          },
        })
      } catch {
        submitLoading.value = false
      }

      let videoCode = ''
      if (isAdd.value) {
        videoCode = data1.videoCode
      } else {
        videoCode = record.v?.videoCode
      }
      submitLoading.value = false

      // 成功
      isAdd.value && ctx.emit('successAdd')
      !isAdd.value && ctx.emit('successEdit')
      close()
    }

    return {
      visible,
      submitLoading,
      isAdd,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
      userInfo,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, FileUpload },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
