<template>
  <el-upload
    class="upload-demo"
    :action="globalHost() + '/web/sys/uploadFile'"
    :data="model.postData"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :beforeUpload="handleBeforeUpload"
    @change="handleChange"
    :value="modelValue"
    :limit="1"
    :disabled="disabled || fileList.length > 0"
    :file-list="fileList"
  >
    <div>
      <el-button type="primary">点击上传</el-button>
      <div :style="{ color: 'red' }">上传视频大小限制：80M</div>
    </div>
    <template #file="{ file }">
      <div>
        <!--点击查看-->
        <el-button type="warning" size="mini" @click="handlePreview(file)">点击查看</el-button>
        <!--删除按钮-->
        <el-button type="danger" size="mini" @click="handleRemove(file)">x</el-button>
      </div>
    </template>
  </el-upload>
</template>
<script setup>
import { ref, reactive, toRefs, onMounted, watch } from 'vue'
import { globalHost, getFileNameSuffix } from '@/utils/utils'
import { messageConfig } from 'element-plus'

function formateFileListToValueStr(fileList) {
  return (fileList || []).map((item) => item.url).join(',') ?? ''
}

function formateValueStrToFileList(str) {
  let arr = []
  if (str?.length > 0) {
    arr = str.split(',').map((url, index) => {
      let name = url.split('/')
      name = name[name.length - 1]
      return {
        name: url,
        status: 'success',
        url,
      }
    })
  }
  return arr
}
const propsDefined = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const { modelValue, disabled } = toRefs(propsDefined)
const fileList = ref([])
watch(modelValue, (newVal) => {
  fileList.value = formateValueStrToFileList(newVal)
})
const model = reactive({
  postData: {
    fileType: 'doctor-pc',
    fileExt: '',
  },
})
const handleBeforeUpload = (file) => {
  const suffix = getFileNameSuffix(file.name)
  model.postData.fileExt = suffix
  return true
}
const handlePreview = (file) => {
  if (file?.status == 'success') {
    window.open(file.url)
  } else {
    messageConfig({
      message: '文件还未上传完成，请稍后再试',
      type: 'error',
    })
  }
}
const handleRemove = (file, list) => {
  fileList.value = []
}
const handleChange = (file, list) => {
  fileList.value = list.map((item) => {
    return {
      ...item,
      url: (item.response?.code == '0' && item.response?.data) || item.url,
    }
  })
}
const emit = defineEmits(['update:modelValue'])
watch(fileList, (newVal) => {
  emit('update:modelValue', formateFileListToValueStr(newVal))
})
</script>
<style lang="scss" scoped></style>
